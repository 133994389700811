<template>
  <section class="top-filter">
    <!--    4（请保持当前页面）状态只前端用-->
    <div class="mb12">
      <FilterLayout @doReset="resetForm"
                    @doSearch="doSearch"
                    ref="filterLayoutRef">
        <template #customform>
          <a-form
              ref="formRef"
              class="form rms-form"
              layout="vertical"
              :model="formState"
          >
            <a-form-item label="搜索" name="condition" class="rms-form-item">
              <a-input
                  v-model:value="formState.condition"
                  placeholder="Name/ID Number/GGID"
                  allowClear
              />
            </a-form-item>
            <a-form-item label="是否进入匹配流程" name="status" class="rms-form-item">
              <a-select
                  v-model:value="formState.tag"
                  :options="tagOps"
                  placeholder="请选择"
                  allowClear
              >
              </a-select>
            </a-form-item>
            <a-form-item label="入/离职状态" name="status" class="rms-form-item">
              <a-select
                  v-model:value="formState.enabled"
                  :options="statusOps"
                  placeholder="请选择"
                  allowClear
              >
              </a-select>
            </a-form-item>
          </a-form>
        </template>
        <template #operationBtns>
          <div class="buttons-wrap right">
            <Tooltip title="导出">
              <a-button type="primary" ghost @click="exportClick" v-auth="['system:bpoPersonal:export']">
                <ExportOutlined />
              </a-button>
            </Tooltip>
            <Tooltip title="下载模板">
              <a-button class="ml12" type="primary" ghost @click="downloadClick" v-auth="['system:bpoPersonal:download']">
                <DownloadOutlined />
              </a-button>
            </Tooltip>
            <Tooltip title="导入">
               <a-button
                  class="ml12"
                  type="primary"
                  ghost
                  @click="uploadClick"
                  :disabled="status !== 0 && status !== 2 && status !== 3"
                  v-auth="['system:bpoPersonal:import']"
              >
                 <ImportOutlined />
              </a-button>
            </Tooltip>
          </div>
        </template>
      </FilterLayout>
    </div>
  </section>
</template>
<script>
import {
  defineComponent,
  reactive,
  ref,
  // onMounted,
  // nextTick,
  onActivated,
} from "vue";
import {cloneDeep} from "lodash";
import { DownloadOutlined,ExportOutlined,ImportOutlined } from "@ant-design/icons-vue";

import Tooltip from '@/components/Tooltip'
export default defineComponent({
  name: "TopFilter",
  components: { DownloadOutlined,ExportOutlined,ImportOutlined,Tooltip },
  props: {
    insertCount: Number,
    updateCount: Number,
    status: Number,
    createOperator: String,
    operationTime: String,
    rpaStatusData: Object
  },
  emits: [
    "search",
    "export-click",
    "download-click",
    "upload-click",
    "refresh-table",
  ],
  setup(props, ctx) {
    const formRef = ref();

    const resetForm = () => {
      formState.enabled = undefined;
      formState.tag = undefined;
      formState.condition = '';
      // formRef.value.resetFields();
      updateParams()
      ctx.emit("search", currentState);
    };

    const formState = reactive({
      tag: undefined,
      enabled: undefined,
      condition: "",
    });
    let currentState  = {}
    const updateParams =() => {
      currentState = cloneDeep(formState);
    }
    const doSearch = () => {
      updateParams()
      ctx.emit("search", currentState);
    };
    const downloadClick = () => {
      ctx.emit("download-click");
    };
    const uploadClick = () => {
      ctx.emit("upload-click");
    };
    const exportClick = () => {
      ctx.emit("export-click", currentState);
    };
    // onMounted(() => {
    //   nextTick(doSearch);
    // });
    onActivated(() => {
      doSearch();
    });

    const statusOps = ref([
      {
        value: 1,
        label: "在职",
      },
      {
        value: 0,
        label: "离职",
      },
    ]);
    const tagOps = ref([
      {
        value: 0,
        label: "否",
      },
      {
        value: 1,
        label: "是",
      },
    ]);

    return {
      statusOps,
      tagOps,
      exportClick,
      formState,
      formRef,
      doSearch,
      resetForm,
      downloadClick,
      uploadClick,
      updateParams,
    };
  },
});
</script>
<style scoped lang="less">
.top-filter {
  width: 100%;
}
.buttons-wrap{
  :deep(.anticon){
    font-size: 20px;
  }
}
</style>
