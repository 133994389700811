<template>
  <section ref="bpoRef" class="org-list" style="position: relative">
    <a-table
      ref="bpoListRef"
      :columns="columns"
      :data-source="bpoList"
      rowKey="id"
      :scroll="{
        x: 7800,
        y: tableHeight,
        scrollToFirstRowOnChange: true,
      }"
      :pagination="pagination"
      :rowClassName="(record) => (record.enabled === 0 ? 'bg-color-gray' : null)"
    >
      <template #action="{ record }">
<!--        <span
          :class="`mr16 ${
            record.enabled === 1
              ? 'operationLink underline'
              : 'operationLink underline disabled'
          }`"
          @click="handleUpdateClick(record)"
          >修改tag</span
        >
        <span
          :class="`${
            record.enabled === 1
              ? 'redLink underline'
              : 'operationLink underline'
          }`"
          @click="handleStopClick(record)"
          >{{ record.enabled === 1 ? "停" : "启" }}用</span
        >-->
        <ActionBtn v-bind="record" :btnList="btnList" @FormOutlined="handleUpdateClick(record)" @DeleteOutlined="handleStopClick(record)" @PoweroffOutlined="handleStopClick(record)" />
      </template>
    </a-table>
    <div class="message1" v-if="dataBoxShow">
      RPA数据：
      <span>新增数据数:</span
      >{{ rpaStatusData.insertCount ? rpaStatusData.insertCount : 0 }}
      <span>, 更新数据数:</span
      >{{ rpaStatusData.updateCount ? rpaStatusData.updateCount : 0 }}
      <span>, 任务状态:</span
      >{{
        rpaStatusData.status == 1
          ? "进行中"
          : rpaStatusData.status == 2
          ? "已完成"
          : rpaStatusData.status == 3
          ? "已失败"
          : rpaStatusData.status == 4
          ? "请保持当前页面"
          : "未进行导入"
      }}
      <span>, 操作人:</span
      >{{ rpaStatusData.createOperator ? rpaStatusData.createOperator : "无" }}
      <span>, 操作时间:</span
      >{{ rpaStatusData.operationTime ? rpaStatusData.operationTime : "无" }}
    </div>
    <div class="message2" v-if="dataBoxShow">
      手动导入数据：
      <span>新增数据数:</span>{{ insertCount ? insertCount : 0 }}
      <span>, 更新数据数:</span>{{ updateCount ? updateCount : 0 }}
      <span>, 任务状态:</span
      >{{
        status == 1
          ? "进行中"
          : status == 2
          ? "已完成"
          : status == 3
          ? "已失败"
          : status == 4
          ? "请保持当前页面"
          : "未进行导入"
      }}
      <span>, 操作人:</span>{{ createOperator ? createOperator : "无" }}
      <span>, 操作时间:</span>{{ operationTime ? operationTime : "无" }}
    </div>

    <a-modal
      :getContainer="()=>$refs.bpoRef"
      v-model:visible="modalVisible"
      width="29.2vw"
      title="修改Tag"
      ok-text="确定"
      cancel-text="取消"
      @ok="handleOk"
      centered
    >
      <div class="mb12">
        <span>是否进入匹配流程：</span>
        <a-select style="width: 100px" v-model:value="tag" :options="tagOps" />
      </div>
    </a-modal>
  </section>
  <div class="message3" v-if="noDataShow">
    RPA数据：
    <span>新增数据数:</span
    >{{ rpaStatusData.insertCount ? rpaStatusData.insertCount : 0 }}
    <span>, 更新数据数:</span
    >{{ rpaStatusData.updateCount ? rpaStatusData.updateCount : 0 }}
    <span>, 任务状态:</span
    >{{
      rpaStatusData.status == 1
        ? "进行中"
        : rpaStatusData.status == 2
        ? "已完成"
        : rpaStatusData.status == 3
        ? "已失败"
        : rpaStatusData.status == 4
        ? "请保持当前页面"
        : "未进行导入"
    }}
    <span>, 操作人:</span
    >{{ rpaStatusData.createOperator ? rpaStatusData.createOperator : "无" }}
    <span>, 操作时间:</span
    >{{ rpaStatusData.operationTime ? rpaStatusData.operationTime : "无" }}
  </div>
  <div class="message4" v-if="noDataShow">
    手动导入数据：
    <span>新增数据数:</span>{{ insertCount ? insertCount : 0 }}
    <span>, 更新数据数:</span>{{ updateCount ? updateCount : 0 }}
    <span>, 任务状态:</span
    >{{
      status == 1
        ? "进行中"
        : status == 2
        ? "已完成"
        : status == 3
        ? "已失败"
        : status == 4
        ? "请保持当前页面"
        : "未进行导入"
    }}
    <span>, 操作人:</span>{{ createOperator ? createOperator : "无" }}
    <span>, 操作时间:</span>{{ operationTime ? operationTime : "无" }}
  </div>

  <ModalBox v-model="modelValue" :ok="handleOk1" :content="content" :showIconCom="'ExclamationCircleOutlined'" />
</template>
<script>
import {
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { useTableHeight, setColumnWidth } from "@/utils/common";
import { message } from "ant-design-vue";
import { useStore } from "vuex";
import ModalBox from '@/components/ModalBox'
import ActionBtn from '@/components/ActionBtn'

const btnList = [
  {
    name: '修改tag',
    icon: 'FormOutlined',
    auth: []
  },
  {
    name: '删除',
    icon: 'DeleteOutlined',
    auth: []
  },
]
export default defineComponent({
  name: "CostCtrList",
  props: {
    insertCount: Number,
    updateCount: Number,
    status: Number,
    createOperator: String,
    operationTime: String,
    rpaStatusData: Object,
  },
  components: {ActionBtn, ModalBox},
  setup() {
    const { $api } = useGlobalPropertyHook();
    const store = useStore();
    const bpoListRef = ref();
    const noDataShow = ref(false);
    const dataBoxShow = ref("");
    const columns = [
      {
        title: "Cost Ctr",
        dataIndex: "costCtr",
        key: "costCtr",
        width: 200,
        ellipsis: true,
      },
      {
        title: "Tag",
        dataIndex: "tag",
        key: "tag",
        ellipsis: true,
        customRender: ({ text }) => (text === 0 ? "否" : "是"),
      },
      {
        title: "Cost Center",
        dataIndex: "costCenter",
        key: "costCenter",
        width: 300,
        ellipsis: true,
      },
      {
        title: "GGID",
        dataIndex: "ggid",
        key: "ggid",
        width: 200,
        ellipsis: true,
      },
      {
        title: "ID Number",
        dataIndex: "idNumber",
        key: "idNumber",
        width: 200,
        ellipsis: true,
      },
      {
        title: "Pers.No.",
        dataIndex: "persNo",
        key: "persNo",
        width: 200,
        ellipsis: true,
      },
      {
        title: "Local ID",
        dataIndex: "localId",
        key: "localId",
        width: 200,
        ellipsis: true,
      },
      {
        title: "First name",
        dataIndex: "firstName",
        key: "firstName",
        width: 200,
        ellipsis: true,
      },
      {
        title: "Last name",
        dataIndex: "lastName",
        key: "lastName",
        width: 200,
        ellipsis: true,
      },
      {
        title: "Chinese Name",
        dataIndex: "chineseName",
        key: "chineseName",
        width: 200,
        ellipsis: true,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        width: 300,
        ellipsis: true,
      },
      {
        title: "Capgemini Start Date",
        dataIndex: "onboardDate",
        key: "onboardDate",
        width: 250,
        ellipsis: true,
      },
      {
        title: "Termination Date",
        dataIndex: "terminationDate",
        key: "terminationDate",
        width: 250,
        ellipsis: true,
      },
      {
        title: "Company Code",
        dataIndex: "companyCode",
        key: "companyCode",
        width: 350,
        ellipsis: true,
      },
      {
        title: "Service Line Text",
        dataIndex: "serviceLine",
        key: "serviceLine",
        width: 250,
        ellipsis: true,
      },
      {
        title: "Sub-Service Line Text",
        dataIndex: "subServiceLine",
        key: "subServiceLine",
        width: 250,
        ellipsis: true,
      },
      {
        title: "Base Location",
        dataIndex: "workLocation",
        key: "workLocation",
        width: 250,
        ellipsis: true,
      },
      {
        title: "Job",
        dataIndex: "job",
        key: "job",
        width: 200,
        ellipsis: true,
      },
      {
        title: "Grade",
        dataIndex: "psGroup",
        key: "psGroup",
        width: 100,
        ellipsis: true,
      },
      {
        title: "Job Category text",
        dataIndex: "jobCategory",
        key: "jobCategory",
        width: 250,
        ellipsis: true,
      },
      {
        title: "Reviewer Global ID",
        dataIndex: "lmGlobalId",
        key: "lmGlobalId",
        width: 250,
        ellipsis: true,
      },
      {
        title: "Reviewer Name (Last, First)",
        dataIndex: "lmName",
        key: "lmName",
        width: 350,
        ellipsis: true,
      },
      {
        title: "Reviewer Email",
        dataIndex: "lmEmail",
        key: "lmEmail",
        width: 350,
        ellipsis: true,
      },
      {
        title: "WORK LOCATION(GROUP)",
        dataIndex: "lmWorkLocation",
        key: "lmWorkLocation",
        width: 350,
        ellipsis: true,
      },
      {
        title: "INVENT",
        dataIndex: "invent",
        key: "invent",
        width: 200,
        ellipsis: true,
      },
      {
        title: "Reason for Action",
        dataIndex: "reasonForAction",
        key: "reasonForAction",
        width: 350,
        ellipsis: true,
      },
      {
        title: "Leaving Reason Description",
        dataIndex: "leavingReasonDescription",
        key: "leavingReasonDescription",
        width: 350,
        ellipsis: true,
      },
      {
        title: "Gender Key",
        dataIndex: "genderKey",
        key: "genderKey",
        width: 200,
        ellipsis: true,
      },
      {
        title: "Cost Rate Code",
        dataIndex: "crc",
        key: "crc",
        width: 250,
        ellipsis: true,
      },
      {
        title: "Mobile Phone",
        dataIndex: "phoneNo",
        key: "phoneNo",
        width: 200,
        ellipsis: true,
      },
      {
        title: "二级结构",
        dataIndex: "skillDept",
        key: "skillDept",
        width: 220,
        ellipsis: true,
      },
      {
        title: "Role ID",
        dataIndex: "positionId",
        key: "positionId",
        width: 220,
        ellipsis: true,
      },
      {
        title: "Role",
        dataIndex: "position",
        key: "position",
        width: 220,
        ellipsis: true,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: 200,
        ellipsis: true,
      },
    ];
    if (
      store.state.app.perms.includes("system:infoImport:staffInfos:operation")
    ) {
      columns.push({
        title: "操作",
        key: "operation",
        fixed: "right",
        width: 100,
        slots: { customRender: "action" },
      });
    }
    const pagination = reactive({
      current: 1,
      pageSize: 12,
      pageSizeOptions: ['12','20','30','40'],
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        doSearch(searchCondition);
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        doSearch(searchCondition);
      },
    });
    const bpoList = ref();
    let searchCondition = {};
    const tableHeight = ref(0);

    const doSearch = () => {
      const params = {
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
        condition: searchCondition.condition,
        tag: searchCondition.tag,
        enabled: searchCondition.enabled,
      };
      $api.queryAllStaffInfo(params).then((res) => {
        pagination.total = res.data.total;
        bpoList.value = res.data.resource;
        if (res.data.total == 0) {
          dataBoxShow.value = false;
          noDataShow.value = true;
        } else {
          dataBoxShow.value = true;
          noDataShow.value = false;
        }
        const customerColumn = [
          "costCenter",
          "email",
          "companyCode",
          "job",
          "reasonForAction",
          "leavingReasonDescription",
          "lmEmail",
        ];
        bpoList.value.length &&
          Object.assign(
            columns,
            setColumnWidth(columns, bpoList.value, customerColumn)
          );
      });
    };

    const searchClick = (searchParams) => {
      pagination.current = 1;
      searchCondition = searchParams;
      doSearch(searchCondition);
    };

    const tableWidth = window.innerWidth;

    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight();
      });
    };

    window.addEventListener("resize", getSize);

    onMounted(() => {
      getSize();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });
    const editRecord = ref({});
    const tag = ref();
    const modalVisible = ref(false);
    const tagOps = ref([
      {
        value: 0,
        label: "否",
      },
      {
        value: 1,
        label: "是",
      },
    ]);
    const handleUpdateClick = (record) => {
      console.log(record, 'record')
      editRecord.value = record;
      tag.value = record.tag;
      modalVisible.value = true;
    };
    const handleOk = () => {
      $api
        .updateStaffInfoTag({ ...editRecord.value, tag: tag.value })
        .then(() => {
          modalVisible.value = false;
          message.success("修改成功");
          doSearch();
        });
    };
    const modelValue = ref(false)
    const content = ref('')
    const modalData = ref()
    const handleOk1 = () => {
      $api.updateStaffInfoEnabled({
            ...modalData.value,
            enabled: modalData.value.enabled === 0 ? 1 : 0,
          })
          .then(() => {
            message.success("操作成功");
            doSearch();
          });
    }
    const handleStopClick = (record) => {
      //content.value = `确定${record.enabled === 0 ? "启用" : "停用"}该人员信息吗？`
      content.value = `是否确定删除该人员信息？`
      modalData.value = record
      modelValue.value = true
      /*Modal.confirm({
        title: `确定${record.enabled === 0 ? "启用" : "停用"}该人员信息吗？`,
        okText: "确定",
        cancelText: "取消",
        onOk() {
          $api
            .updateStaffInfoEnabled({
              ...record,
              enabled: record.enabled === 0 ? 1 : 0,
            })
            .then(() => {
              message.success("操作成功");
              doSearch();
            });
        },
        centered: true,
        closable: true,
      });*/
    };

    return {
      bpoListRef,
      columns,
      pagination,
      bpoList,
      searchClick,
      tableHeight,
      tableWidth,
      doSearch,
      handleUpdateClick,
      handleStopClick,
      handleOk,
      editRecord,
      modalVisible,
      tag,
      tagOps,
      dataBoxShow,
      noDataShow,
      modelValue,
      content,
      handleOk1,
      btnList
    };
  },
});
</script>
<style scoped lang="less">
@import "~@/style/aModal.less";
//:deep(.ant-table-header) {
//  padding-bottom: 1px !important;
//}
.org-list {
  display: flex;
  width: 100%;
  //max-height: calc(100% - 88px);

  .ant-table-wrapper {
    flex: 1;
    width: 0;
  }

  .org-info {
    width: 300px;
    //margin-left: 20px;
    background: #f4f4f4;
  }
  .message1 {
    position: absolute;
    bottom: 30px;
    height: 20px;
    line-height: 20px;
    color: #6f6f6f;
    span {
      color: #b3b3b3;
    }
  }
  .message2 {
    position: absolute;
    bottom: 13px;
    height: 20px;
    line-height: 20px;
    color: #6f6f6f;
    span {
      color: #b3b3b3;
    }
  }
}
.message3 {
  position: absolute;
  margin-top: 20px;
  height: 20px;
  line-height: 20px;
  color: #6f6f6f;
  span {
    color: #b3b3b3;
  }
}
.message4 {
  position: absolute;
  margin-top: 40px;
  height: 20px;
  line-height: 20px;
  color: #6f6f6f;
  span {
    color: #b3b3b3;
  }
}
:deep(.bg-color-gray) td {
  background-color: #f4f4f4;
}
</style>
