<template>
  <section class="app-container">
    <Filter
      @search="handleSearch"
      @download-click="downloadClick"
      @upload-click="uploadClick"
      @export-click="exportClick"
      :insertCount="insertCount"
      :updateCount="updateCount"
      :status="status"
      :createOperator="createOperator"
      :operationTime="operationTime"
      :rpaStatusData="rpaStatusData"
      @refresh-table="handleResetTable"
    />
    <import-modal
      v-model:visible="modalVisible"
      apiFuncName="importStaffInfoList"
      :uploadParams="{tag}"
      @closeModal="handleCloseModal"
      @resetTable="handleResetTable"
      @updateMessage="getMessage"
      @uploadStart="uploadStart"
      @updateError="updateError"
    >
      <div class="mb12">
        <div class="lheight">是否进入匹配流程：</div>
        <a-select
          style="width: 100px; z-index: 10002"
          v-model:value="tag"
          :options="tagOps"
        />
      </div>
    </import-modal>
    <Bpo
        ref="bpoListRef"
        :insertCount="insertCount"
        :updateCount="updateCount"
        :status="status"
        :createOperator="createOperator"
        :operationTime="operationTime"
        :rpaStatusData="rpaStatusData"
    ></Bpo>
  </section>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import Filter from "./components/Filter.vue";
import Bpo from "./components/Bpo.vue";
import { downloadFile } from "@/utils/common";
import { useGlobalPropertyHook } from "@/hooks/common";
import ImportModal from "@/components/ImportModal";

export default defineComponent({
  name: "importBpoPersonal",
  components: {
    ImportModal,
    Bpo,
    Filter,
  },
  setup() {
    const { $api } = useGlobalPropertyHook();

    const bpoListRef = ref();
    const handleSearch = (searchParams) => {
      bpoListRef.value.searchClick(searchParams);
    };
    const insertCount = ref(0);
    const updateCount = ref(0);
    const status = ref();
    const createOperator = ref("无");
    const operationTime = ref();
    const rpaStatusData = ref({});

    const getMessage = () => {
      $api.getImportResult().then((res) => {
        insertCount.value = res.data.insertCount;
        updateCount.value = res.data.updateCount;
        status.value = res.data.status;
        createOperator.value = res.data.createOperator;
        operationTime.value = res.data.operationTime;
        if (status.value === 1) {
          let timer = setTimeout(() => {
            getMessage();
            clearTimeout(timer);
          }, 5000);
        }
      });
    };
    const modalVisible = ref(false);
    const uploadClick = () => {
      modalVisible.value = true;
    };
    const handleCloseModal = () => {
      modalVisible.value = false;
    };
    const handleResetTable = () => {
      bpoListRef.value.doSearch();
    };

    const downloadClick = () => {
      $api.downloadStaffInfoList().then((res) => {
        downloadFile(res.file, res.filename);
      });
    };
    const exportClick = (searchParams) => {
      const params = {
        tag: searchParams.tag,
        enabled: searchParams.enabled,
        condition: searchParams.condition,
      }
      $api.exportBpoData(params).then((res) => {
        downloadFile(res.file, res.filename);
      });
    };
    let oldStatus;
    const uploadStart = () => {
      oldStatus = status.value;
      status.value = 4;
    };
    const updateError = () => {
      status.value = oldStatus;
    };
    const tagOps = ref([
      {
        value: 0,
        label: "否",
      },
      {
        value: 1,
        label: "是",
      },
    ]);
    const tag = ref(1);

    const getRpaStatus = () => {
      $api.getRPAStatus().then(res => {
        rpaStatusData.value = res.data;
      })
    }

    onMounted(() => {
      getMessage();
      getRpaStatus();
    });

    return {
      tagOps,
      tag,
      bpoListRef,
      handleSearch,
      exportClick,
      downloadClick,
      modalVisible,
      uploadClick,
      handleCloseModal,
      handleResetTable,
      insertCount,
      updateCount,
      status,
      createOperator,
      operationTime,
      rpaStatusData,
      getMessage,
      uploadStart,
      updateError,
    };
  },
});
</script>
<style scoped lang="less">
.lheight{
  line-height: 35px;
}
</style>
